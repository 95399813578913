import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/RongIm.png';

const _withScopeId = n => (_pushScopeId("data-v-70fb258c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "rong-im-panel"
};
const _hoisted_2 = {
  key: 0,
  class: "rong-im-icon-panel"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  class: "rong-im-message-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoginButton = _resolveComponent("LoginButton");

  const _component_MessageList = _resolveComponent("MessageList");

  const _component_Message = _resolveComponent("Message");

  const _component_ImDialog = _resolveComponent("ImDialog");

  return _openBlock(), _createElementBlock(_Fragment, null, [!_ctx.isGLDB() && !_ctx.isQTUM() ? (_openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.imStore.RongIMShow ? (_openBlock(), _createElementBlock("div", _hoisted_2, [!_ctx.isLogin ? (_openBlock(), _createBlock(_component_LoginButton, {
    key: 0
  }, {
    button: _withCtx(({
      login
    }) => [_createElementVNode("img", {
      src: _imports_0,
      alt: "icon",
      class: "rong-im-icon",
      onClick: login
    }, null, 8, _hoisted_3)]),
    _: 1
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _imports_0,
    alt: "icon",
    class: "rong-im-icon",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    (...args) => _ctx.openRongIm && _ctx.openRongIm(...args))
  }))])) : _createCommentVNode("", true), _createVNode(_Transition, {
    name: "el-fade-in"
  }, {
    default: _withCtx(() => [_ctx.imStore.RongIMShow ? (_openBlock(), _createElementBlock("div", _hoisted_4, [!_ctx.imStore.targetId ? (_openBlock(), _createBlock(_component_MessageList, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_Message, {
      key: 1
    }))])) : _createCommentVNode("", true)]),
    _: 1
  })])) : _createCommentVNode("", true), _createVNode(_component_ImDialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => _ctx.visible = $event)
  }, null, 8, ["visible"])], 64);
}