/* unplugin-vue-components disabled */import { defineComponent, ref, computed, watch, nextTick } from 'vue';
import { parseDate, parseTime, secondDiff } from '@/utils/index';
import requester from '@/libs/request';
export default defineComponent({
  setup() {
    const imStore = useImStore();
    const {
      targetHistory,
      targetId
    } = storeToRefs(imStore);

    const closeRongIm = () => {
      imStore.RongIMShow = false;
    };

    const backMessageList = () => {
      imStore.initTargetAction();
    };

    const list = computed(() => {
      if (!targetHistory.value.length) return [];
      const result = [];
      const map = new Map();
      targetHistory.value.forEach(item => {
        const time = parseTime(item.sentTime, '{y}-{m}-{d}');

        if (!map.has(time)) {
          map.set(time, [item]);
        } else {
          map.set(time, [...map.get(time), item]);
        }
      });

      for (const [key, value] of map.entries()) {
        if (parseTime(new Date(), '{y}-{m}-{d}') === key) {
          value.forEach((element, index) => {
            if (!result.length) {
              result.push({
                timeStr: value[index].sentTime,
                children: [element]
              });
            } else {
              const secondVal = secondDiff(element.sentTime, result[result.length - 1].timeStr);

              if (secondVal > 5) {
                result.push({
                  timeStr: value[index].sentTime,
                  children: [element]
                });
              } else {
                result[result.length - 1].children.push(element);
              }
            }
          });
        } else {
          result.push({
            timeStr: value[0].sentTime,
            children: value
          });
        }
      }

      return result;
    });
    const content = ref();

    const send = () => {
      if (!content.value) return;
      const messages = imStore.RongIMLib.TextMessage({
        content: content.value
      });
      content.value = '';
      imStore.sendMessageAction(messages);
    };

    const hisIndex = ref(0);
    let option = ref({
      timestamp: 0,
      count: 20,
      order: 0
    });

    const init = async () => {
      option.value = {
        timestamp: 0,
        count: 20,
        order: 0
      };
      hisIndex.value = 0;
      await imStore.initMessageAction();
      await imStore.getHistoryMessagesAction(option.value);
      hisIndex.value = targetHistory.value.length;
    };

    const loading = ref(false);
    const messageLoading = ref(false);

    const scrollChange = e => {
      if (!imStore.targetHasMore) return;
      const el = e.srcElement;
      const {
        scrollTop
      } = el;

      if (scrollTop === 0) {
        loading.value = true;
        option.value = {
          timestamp: targetHistory.value[0].sentTime,
          count: 20,
          order: 0
        };
        imStore.getHistoryMessagesAction(option.value).then(() => {
          let len = targetHistory.value.length - hisIndex.value;
          document.querySelectorAll('.scroll-item')[len].scrollIntoView();
          hisIndex.value = targetHistory.value.length;
          loading.value = false;
        });
      }
    };

    watch(targetId, (val, oldVal) => {
      if (val !== oldVal) {
        init();
      }
    }, {
      immediate: true
    });
    watch(targetHistory, val => {
      nextTick(() => {
        if (!loading.value) {
          const el = document.querySelector('.message-content');
          el.scrollTop = el.scrollHeight;
        }

        imStore.clearMessagesUnreadStatusAction();
      });
    }, {
      deep: true
    });
    const fileList = ref([]);

    const handleFileChange = async file => {
      try {
        messageLoading.value = true;
        const newFile = new FormData();
        newFile.append('file', file.raw);
        newFile.append('type', '1');
        const data = await requester({
          url: '/file/upload',
          method: 'post',
          data: newFile
        });
        const messages = imStore.RongIMLib.ImageMessage({
          imageUri: data.uri // 图片的远程访问地址

        });
        await imStore.sendMessageAction(messages);
        fileList.value = [];
        messageLoading.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    const messageName = computed(() => {
      return imStore.conversationList.find(k => k.targetId === targetId.value)?.userName;
    });
    const messagePic = computed(() => {
      return imStore.conversationList.find(k => k.targetId === targetId.value)?.pic;
    });

    const handleImgOnload = () => {
      nextTick(() => {
        if (option.value.timestamp === 0) {
          const el = document.querySelector('.message-content');
          el.scrollTop = el.scrollHeight;
        }
      });
    };

    return {
      parseDate,
      imStore,
      closeRongIm,
      backMessageList,
      list,
      content,
      send,
      option,
      init,
      loading,
      scrollChange,
      messageLoading,
      hisIndex,
      handleFileChange,
      fileList,
      messageName,
      messagePic,
      handleImgOnload
    };
  }

});