import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/rongIm/close.png';

const _withScopeId = n => (_pushScopeId("data-v-d04a274c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "rong-im-dialog"
};
const _hoisted_2 = {
  class: "rong-im-dialog-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMembershipCard = _resolveComponent("SelectMembershipCard");

  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('posts.joinGroup')), 1), _createElementVNode("div", {
    class: "el-button btnBg nav-btn rounded-xl h-11 w-full",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    (...args) => _ctx.goNFT && _ctx.goNFT(...args))
  }, _toDisplayString(_ctx.$t('posts.BecomeNFTMember')), 1), _createElementVNode("img", {
    src: _imports_0,
    onClick: _cache[1] || (_cache[1] = //@ts-ignore
    (...args) => _ctx.close && _ctx.close(...args))
  })])])) : _createCommentVNode("", true), _createVNode(_component_SelectMembershipCard, {
    ref: "SelectMembershipCardRef"
  }, null, 512)], 64);
}