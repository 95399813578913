import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:visible'],

  setup(props, context) {
    const SelectMembershipCardRef = ref();
    const value = ref(false);
    watch(() => props.visible, val => {
      console.log(val);

      if (val) {
        value.value = props.visible;
      }
    });

    const close = () => {
      value.value = false;
      context.emit('update:visible', false);
    };

    const goNFT = async () => {
      close();
      SelectMembershipCardRef.value.toggle();
    };

    return {
      value,
      close,
      goNFT,
      SelectMembershipCardRef
    };
  }

});