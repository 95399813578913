/* unplugin-vue-components disabled */import { defineComponent, onMounted, watch } from 'vue';
import messageList from './components/messageList.vue';
import message from './components/message.vue';
import { ElMessage } from 'element-plus';
import { isGLDB, isQTUM } from '@/config';
export default defineComponent({
  components: {
    messageList,
    message
  },

  setup() {
    const buyerStore = useBuyerStore();
    const {
      isLogin,
      token
    } = storeToRefs(buyerStore);
    const {
      shop
    } = storeToRefs(useSellerStore());
    const imStore = useImStore();
    const visible = ref(false);

    const openRongIm = async () => {
      if (!imStore.RongConnectStatus) {
        await init();
      }

      if (!imStore.isNFT) {
        visible.value = true;
        return;
      }

      imStore.RongIMShow = true;
    };

    const closeRongIm = () => {
      imStore.RongIMShow = false;
    };

    const init = async () => {
      try {
        await imStore.connectAction();

        if (imStore.isNFT) {
          await imStore.changeRongConnectStatus();
          await imStore.getConversationListAction();
        }
      } catch (error) {
        ElMessage.error('Failed to connect to the IM service');
      }
    };

    onMounted(() => {
      if (isLogin.value) {
        init();
      }
    });
    watch(token, val => {
      if (val) {
        imStore.logoutAction();
      }
    });
    return {
      isLogin,
      imStore,
      openRongIm,
      closeRongIm,
      visible,
      shop,
      isGLDB,
      isQTUM
    };
  }

});