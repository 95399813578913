import { NftsChain } from '../interface/nfts'

export const WALLET_TYPE = {
  MetaMask: 0,
}

const isMainnet = process.env.VUE_APP_NETWORK === 'mainnet'
export const NETWORK_URL: {
  [k in NftsChain]: {
    name: 'Ethereum' | 'Goerli' | 'Polygon' | 'Mumbai' | 'Arbitrum'
    url: string
    openseaUrl: string
  }
} = {
  0: {
    name: isMainnet ? 'Ethereum' : 'Goerli',
    url: isMainnet ? 'https://etherscan.io' : 'https://goerli.etherscan.io',
    openseaUrl: 'assets/',
  },
  1: {
    name: isMainnet ? 'Polygon' : 'Mumbai',
    url: isMainnet ? 'https://polygonscan.com' : 'https://mumbai.polygonscan.com',
    openseaUrl: isMainnet ? 'assets/matic/' : 'assets/mumbai/',
  },
  5: {
    name: isMainnet ? 'Arbitrum' : 'Mumbai',
    url: isMainnet ? 'https://arbiscan.io' : 'https://mumbai.arbiscan.io',
    openseaUrl: isMainnet ? 'assets/' : 'assets/mumbai/',
  },
}

export const UNIT_TYPE: any = {
  0: 'ETH',
  1: 'USD',
  2: 'USD',
  5: 'ETH',
}

export const ACCEPT = {
  IMAGE: '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.GIF,.gif',
  VIDEO: '.MP4,.mp4',
  AUDIO: '.MP3,.mp3,.OGG,.ogg,.wav,.WAV',
  FILE: '',
}

/*
特殊商家配置
*/

// 不展示facebook google 登录的host
export const NO_SHOW_SOCIAL_LOGIN_HOST: string[] = ['gldb']

export function isGLDB(): boolean {
  const url = window.location.host
  const host = url.split('.')[0]
  return NO_SHOW_SOCIAL_LOGIN_HOST.some(t => t === host)
  // return true
}

// 不展示facebook google 登录的host
export const QTUM_LOGIN_HOST: string[] = [
  'quantum-music-test',
  'quantummusic',
  'care',
  'liangziyinyue',
]
export function isQTUM(): boolean {
  const url = window.location.host
  const host = url.split('.')[0]
  return QTUM_LOGIN_HOST.some(t => t === host)
}

// 不展示facebook google 登录的host
export const DFANS_LOGIN_HOST: string[] = ['dfans']
export function isDFANS(): boolean {
  const url = window.location.host
  const host = url.split('.')[0]
  return DFANS_LOGIN_HOST.some(t => t === host)
}

// 绿联新版需求
export const NO_SHOW_GLDB_NEW_HOST: string[] = ['localhost:9990', 'newdigitalbank', 'baggio4']
export function isGLDBNEW(): boolean {
  const url = window.location.host
  const host = url.split('.')[0]
  return NO_SHOW_GLDB_NEW_HOST.some(t => t === host)
}
