/* unplugin-vue-components disabled */import { defineComponent } from 'vue';
import { parseDate } from '@/utils/index';
export default defineComponent({
  setup() {
    const imStore = useImStore();

    const closeRongIm = () => {
      imStore.RongIMShow = false;
    };

    const handleActive = async (item, index) => {
      await imStore.chioceTargetAction(item);
    };

    return {
      imStore,
      closeRongIm,
      handleActive,
      parseDate
    };
  }

});